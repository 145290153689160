import apiService from '@/api/index.js'

const routeToFunctionMap = {
	userEdit: 'getEditPageData',
	userPreOrders: 'getOrders',
	userIndividualOrder: 'getOrder',
	userFavorites: 'getEditPageData'
}

export default {
	endpoint: 'user',
	type: 'User',
	async getPage(routeTo) {
		const postData = {
			endpoint: this.endpoint,
			type: 'User',
			func_name: routeToFunctionMap[routeTo.name],
			data: {
				user: routeTo.params.user,
				order_id: routeTo.params.order_id
			},
			firstLoad: routeTo.firstLoad || false
		}
		return await apiService.get(postData)
	},
	async edit(data) {
		return await apiService.post(data)
	},
	async updateUserProfile(data) {
		const postData = {
			endpoint: this.endpoint,
			func_name: 'updateProfile',
			data: data,
			type: 'User'
		}
		const response = await apiService.post(postData)
		return response.data
	},
	async saveImage(data) {
		const postData = {
			user: data.user_id,
			image: data.image,
			main: data.main,
			endpoint: this.endpoint,
			func_name: 'saveImage',
			type: 'User'
		}
		return await apiService.post(postData)
	},
	async deleteImage(data) {
		const postData = {
			business_id: data.listing_id || data.business_id,
			image_id: data.image_id,
			endpoint: this.endpoint,
			func_name: 'deleteImage',
			type: 'User'
		}
		return await apiService.post(postData)
	},
	async saveStrainCheckin(data) {
		const postData = {
			strain: data.seo || data.id,
			review: data.review,
			ratings: data.ratings,
			func_name: 'checkin',
			type: 'Strains'
		}
		return await apiService.post(postData)
	}
}
