// first we need to import the api
import destructureOrder from '@/api/helpers/destructureOrder.js'
import publicUserApi from '@/api/public/userService.js'
import privateUserApi from '@/api/user/userPrivateService.js'
import { logError } from '@/utils/error-handling.js'

const routeMutations = {
	setReviewsPageData: function (state, data) {
		state.reviews = data.reviews
	},
	setPhotosPageData: function (state, data) {
		state.photos = data.user_photos
	},
	setStrainsPageData: function (state, data) {
		state.strains = data.user_strains
		state.wishlist = data.user_wishlist_strains
	},
	setEditPageData: function (state, data) {
		state.rawData = Object.assign(data.user, data.profile)
	},
	setUserPreOrdersPageData: function (state, data) {
		const destructuredOrders = data.orders.map(obj => destructureOrder(obj))
		state.preOrders = destructuredOrders
	},
	setUserIndividualOrderPageData: function (state, data) {
		state.preOrder = Object.assign({}, destructureOrder(data.order))
	},
	setCommonData: function (state, data) {
		state.profilePicture = data.user.profile_picture
		state.id = parseInt(data.user.user_id)
		state.username = data.user.username || data.user.display_name
		state.reviewCount = parseInt(data.user.review_count)
		state.photoCount = parseInt(data.user.photos_count) || 0
		state.bizName = data.user.bizName
		state.location = data.profile?.location
		state.zip = data.profile?.zip
	}
}

const userInitiatedMutations = {
	appendPhoto: function (state, data) {
		const currentPhotos = state.photos || {}
		let postedPhotoObject = {}
		const newObject = {}
		postedPhotoObject = data
		currentPhotos.ids.push(postedPhotoObject.fileid)
		currentPhotos.items = Object.assign(newObject, currentPhotos.items, { [postedPhotoObject.fileid]: postedPhotoObject })
	},
	saveProfilePicture: function (state, data) {
		state.profilePicture = data.filename || null
	},
	setProfileImage: function (state, imageName) {
		state.newImage = imageName
	},
	deleteImage: function (state, data) {
		const index = state.photos.ids.indexOf(data.image_id)
		delete state.photos.items[data.image_id]
		if (index !== -1) state.photos.ids.splice(index, 1)
	}
}

const userInitiatedActions = {

	uploadPhoto: async function (context, data) {
		try {
			const response = await privateUserApi.saveImage(data)
			if (data.main !== 1) { // if photo is not a profile photo, then append to gallery
				context.commit('appendPhoto', response.data.results.photo)
			} else if (response.data.success) { // if a profile profile photo, save as profilePicture
				context.commit('saveProfilePicture', response.data.results.photo || {})
			}
			return response
		} catch (e) {
			context.dispatch('setError', e, { root: true })
		}
	},
	removePhoto: async function (context, data) {
		const response = await privateUserApi.deleteImage(data)
		context.commit('deleteImage', data)
		return response
	},
	updateUserProfile: async function (context, data) {
		const response = await privateUserApi.updateUserProfile(data)
		context.commit('setCommonData', response.results.updated_user_profile)
		context.commit('setEditPageData', response.results.updated_user_profile)
		return response
	},
	setStrainCheckin: async function (context, data) {
		const response = await privateUserApi.saveStrainCheckin(data)
		if (response.error) throw response.error
		return response
	}
}

const routeToMutationMap = {
	userIndex: 'setReviewsPageData',
	userReviews: 'setReviewsPageData',
	userPhotos: 'setPhotosPageData',
	userStrains: 'setStrainsPageData'
}

const routeToPrivateMutationMap = {
	userEdit: 'setEditPageData',
	userPreOrders: 'setUserPreOrdersPageData',
	userIndividualOrder: 'setUserIndividualOrderPageData',
	userFavorites: 'setEditPageData'
}

const gqlPrivateRoutes = [ 'userFavorites' ]

const initialState = {
	id: 0,
	username: '',
	profilePicture: {},
	location: '',
	zip: 0,
	reviewCount: 0,
	photoCount: 0,
	helpful: 0,
	not_helpful: 0,
	rawData: {
		about: '',
		best_time: '',
		dob: '0000-00-00',
		fav_club: '',
		fav_con: '',
		fav_movie: '',
		fav_strain: '',
		first_ex: '',
		hobbies: '',
		interests: '',
		location: '',
		prefer: null,
		profile_picture: '',
		profileid: '',
		realname: '',
		sex: null,
		tool: '',
		userid: '',
		website: '',
		zip: ''
	},
	reviews: {
		ids: [],
		items: {}
	},
	photos: {},
	strains: {},
	favoriteBusinesses: [],
	preOrder: {
		business: {},
		customer: {},
		timing: {
			estimated: {}
		},
		delivery: {},
		dispatch: {},
		items: {
			ids: [],
			data: {}
		},
		verificationNeeded: false,
		adjustedPrice: ''
	},
	preOrders: []
}

const user = {
	namespaced: true,
	state: () => ({
		...initialState
	}),
	mutations: {
		...userInitiatedMutations,
		...routeMutations
	},
	actions: {
		getPage: async function (context, routeTo) {
			routeTo = Object.assign({}, routeTo)
			let response = {}
			let apiService = publicUserApi
			const routeAction = routeToMutationMap[routeTo.name] || routeToPrivateMutationMap[routeTo.name]

			if (!routeToMutationMap[routeTo.name]) { // determine if the route is a private or public
				if (!routeToPrivateMutationMap[routeTo.name] && !gqlPrivateRoutes.includes(routeTo.name)) {
					throw new Error('No mutation route found for: ' + routeTo.name)
				}
				apiService = privateUserApi
			}

			try {
				response = await apiService.getPage(routeTo) // make your api call
			} catch (e) {
				context.commit('setError', e, { root: true })
			}

			// analyse and process data
			if (response.error && response.error.response.status == 404) {
				context.commit('set404', null, { root: true })
				return
			} else if (response.data && response.data.error) {
				logError(response.data.error)
			}

			if (response.data?.results !== undefined) {
				try {
					context.commit('setCommonData', response.data.results)
					context.commit(routeAction, response.data.results)
					delete response.data.auth // we don't want REST to set auth and battle GQL auth
					context.dispatch('setAppData', response.data, { root: true }) // every module that has routing should be calling this to set meta data and such
				} catch (e) {
					logError(e)
				}
			}
		},
		...userInitiatedActions
	},
	getters: {
		user: state => { return state },
		userName: state => { return state.username },
		reviewCount: state => { return state.reviewCount },
		photoCount: state => { return state.photoCount },
		location: state => { return state.location },
		zip: state => { return state.zip },
		userId: state => { return state.id },
		reviews: state => { return state.reviews },
		photos: state => { return state.photos },
		strains: state => { return state.strains },
		preOrder: state => { return state.preOrder },
		preOrders: state => { return state.preOrders },
		profilePicture: state => { return state.profilePicture },
		userEdit: state => { return Object.assign({}, state.rawData) }
	}
}

export default user
