<template>
	<div
		class="w-full"
		:class="{ public: !canEdit }"
	>
		<div
			v-if="canEdit"
			class="mb-4"
		>
			<h2 class="pt-2 pb-4 text-2xl font-bold">
				Upload Photo
			</h2>
			<form
				v-require-login="{callback: handleUploadPhoto}"
			>
				<WwImageUpload
					:auto-crop="true"
					:auto-crop-area=".99"
					:aspect-ratio="1/1"
					:initial-aspect-ratio="1/1"
					:should-reset="resetImageUploader"
					class="mb-2"
					@image-updated="handleUpdateImage"
				/>
				<input
					v-model="image"
					type="hidden"
				>
				<button
					class="px-3 py-2 font-bold text-white uppercase bg-green-500 rounded-lg"
					type="submit"
					:disabled="!readyToUpload"
				>
					Upload
				</button>
			</form>
		</div>
		<h2 class="pt-2 pb-4 text-2xl font-bold">
			Photos
		</h2>
		<div v-if="!hasPhotos">
			<EmptyList
				image-src="/img/no-photos.png"
				copy="This user has no photos."
			/>
		</div>
		<div
			v-else
			class="-mx-4"
		>
			<div
				class="img-item"
			>
				<TransitionGroup
					class="grid grid-cols-1 gap-3 px-3 md:grid-cols-2 lg:grid-cols-3"
					appear
					name="fade-photo !drag ? 'flip-list' : null"
				>
					<UserPhotoCard
						v-for="(photo, index) in photoItems"
						:id="'photo-' + index"
						:key="`${index}-photo`"
						:photo="photo"
						:photo-id="parseInt(index)"
						:style="{'--index': index}"
						class="p-3 bg-white cursor-pointer"
						@emit-open-modal="openModal(USER_PHOTO_MODAL, index + 1)"
					/>
				</TransitionGroup>
			</div>
			<PhotoCarouselModal
				:modal-id="USER_PHOTO_MODAL"
				title="Gallery"
				:active-slide="modalPhotoId"
				:slides="slides"
				:current-index="modalPhotoId"
			/>
		</div>
	</div>
</template>

<script async>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import destructureUserImage from '@/api/helpers/destructureUserImage.js'
import EmptyList from '@/components/multiUse/EmptyList.vue'
import { USER_PHOTO_MODAL } from '@/constants/modal/names.js'
import { ERROR, SUCCESS } from '@/constants/toast/type.js'
import { logError } from '@/utils/error-handling.js'

const WwImageUpload = () => ({
	component: import('@/components/UI/WwImageUpload.vue')
})

export default {
	components: {
		EmptyList,
		PhotoCarouselModal: () => import('@/components/UI/modals/PhotoCarouselModal.vue'),
		UserPhotoCard: () => import('./components/PhotoCard.vue'),
		WwImageUpload
	},
	data() {
		return {
			resetImageUploader: false,
			isMounted: false,
			image: '',
			customImage: {},
			hover: false,
			drag: false,
			readyToUpload: false,
			modalPhotoId: 0,
			removePickerImage: false,
			USER_PHOTO_MODAL
		}
	},
	computed: {
		hasPhotos() { return this.photos.ids && this.photos.ids.length > 0	},
		slides() {
			const slideArray = []
			for (let i = 0; i < this.photos.ids.length; i++) {
				const photoId = this.photos.ids[i]
				slideArray.push(destructureUserImage(this.photos.items[photoId]))
			}
			return slideArray
		},
		...mapGetters('user', [
			'photos',
			'userId',
			'userName'
		]),
		...mapGetters('auth', [
			'auth'
		]),
		photoItems() {
			return this.hasPhotos && this.photos.items
		},
		uploadData() {
			return {
				user_id: this.userId,
				main: this.main,
				image: this.image
			}
		},
		main() {
			return false
		},
		canEdit () {
			return this.auth.loggedIn && this.auth.id === this.userId
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		...mapActions('user', [
			'uploadPhoto', 'savePhotoOrder'
		]),
		...mapMutations('modal', [ 'showModal' ]),
		...mapMutations('toast', [ 'showToast' ]),
		handleUpdateImage(image) {
			this.image = image
			this.readyToUpload = true
		},
		openModal(modalId, photoId) {
			this.modalPhotoId = photoId
			this.showModal(modalId)
		},
		showErrorToast(error) {
			let errorMessage = 'Error'
			if (error.msg == 'File type not allowed.' && error.file_type !== '') {
				errorMessage = 'File type ' + "'" + error.file_type + "'" + ' not allowed.'
			} else if (error.msg == 'null' || error.msg == '') {
				errorMessage = 'Error'
			}
			this.showToast({
				primaryText: 'Upload failed',
				secondaryText: errorMessage,
				type: ERROR
			})
		},
		async handleUploadPhoto() {
			try {
				const { data } = await this.uploadPhoto(this.uploadData)
				if (data.success == 1) {
					this.showToast({
						primaryText: 'Success',
						secondaryText: 'Photo uploaded',
						type: SUCCESS
					})
					this.readyToUpload = false // resets our readyToUpload boolean to disable the upload button until new image is selected and ready
					this.removePickerImage = true // sends truthy value to ImageUpload.vue that triggers the croppa.remove() function to clear the image picker
				}
			} catch (e) {
				logError(e)
			}
			this.resetImageUploader = true
			await this.$nextTick()
			this.resetImageUploader = false
		},
		openPhotoModal(photoId) {
			this.modalPhotoId = photoId
		},
		resetModalData() {
			this.modalPhotoId = 0
		}
	}
}
</script>
