<template>
	<div class="p-3">
		<div class="flex">
			<button
				v-for="tab in tabs"
				:key="tab.name"
				:class="[
					'cursor-pointer flex-1 text-center text-xl pb-2 mb-4',
					tab.isActive ? 'text-green-500 font-bold border-b-2 border-green-500' : 'border-b border-gray-300'
				]"
				@click="selectTab(tab)"
			>
				{{ tab.name }}
			</button>
		</div>
		<slot />
	</div>
</template>

<script async>
export default {
	emits: [ 'selectTab' ],
	data() {
		return {
			tabs: []
		}
	},
	mounted() {
		this.tabs = this.$children
	},
	methods: {
		selectTab({ name }) {
			this.$emit('selectTab', name)
		}
	}
}
</script>
