<template>
	<div v-show="isActive">
		<slot />
	</div>
</template>

<script async>
export default {
	props: {
		isActive: {
			type: Boolean,
			default: false
		},
		name: {
			type: String,
			required: true
		}
	}
}
</script>
