<template>
	<div>
		<h2 class="pt-2 pb-4 text-2xl font-bold">
			Strains
		</h2>
		<template v-if="emptyState">
			<EmptyList
				image-src="/img/no-menu.jpg"
				copy="This user hasn't checked into any strains yet."
				class="mb-2"
			/>
			<div class="font-bold text-center">
				Check out our <router-link to="/strains">
					strains
				</router-link>.
			</div>
		</template>
		<div v-else>
			<ul class="nav nav-tabs">
				<li
					v-for="tab in tabs"
					:key="tab"
					class="nav-item"
					:class="[
						'nav-item',
						{ active: selected === tab }
					]"
					@click="selected = tab"
				>
					<a class="nav-link active">
						{{ tabName(tab) }}
					</a>
				</li>
			</ul>
			<component
				:is="selected"
				class="tab"
			/>
		</div>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import EmptyList from '@/components/multiUse/EmptyList.vue'

export default {
	components: {
		Checkins: () => import('./components/Checkins.vue'),
		EmptyList,
		UserStrainsCard: () => import('./components/StrainCard.vue')
	},
	data() {
		return {
			tabs: [ 'Checkins' ],
			selected: 'Checkins'
		}
	},
	computed: {
		...mapGetters('user', [
			'strains'
		]),
		...mapGetters('auth', [
			'auth'
		]),
		emptyState () { return !this.strains || !this.strains.ids || this.strains.ids.length < 1	}
	},
	methods: {
		tabName(tab) {
			if (tab == 'CheckIns') {
				return 'Check-Ins'
			} else {
				return tab
			}
		}
	}
}
</script>
