const destructureUserImage = function (image) {
	const {
		fileid: fileId,
		filename: fileName
	} = image

	return {
		fileId,
		fileName,
		type: 'user_images'
	}
}

export default destructureUserImage
