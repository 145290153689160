<template>
	<div class="flex items-center justify-center w-full h-full">
		<loading-spinner class="w-16 h-16" />
	</div>
</template>

<script async>
import LoadingSpinner from '@/components/multiUse/LoadingSpinner.vue'

export default {
	components: {
		LoadingSpinner
	}
}
</script>
