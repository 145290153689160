<template>
	<div class="flex flex-wrap justify-center px-3 mx-auto my-4">
		<EmptyList
			v-if="showEmptyState"
			copy="You haven't chosen any favorite businesses yet."
			class="mb-2"
			image-src="/img/no-photos.png"
		/>
		<div class="flex flex-wrap justify-start w-full">
			<SearchCardBusiness
				v-for="listing in destructuredItems"
				:key="listing.id"
				:result="listing"
				:list-type="BUSINESSES_LIST"
				:is-mobile="false"
				class="m-2"
			/>
		</div>
		<div
			v-if="loading"
			class="grid w-full grid-cols-1 gap-16 py-16 text-xl font-bold text-center"
		>
			<div class="w-full text-gray-300">
				Loading...
			</div>
			<LoadingSpinner class="w-1/4 mx-auto text-gray-300 xl:w-1/6" />
		</div>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import destructureSearchResult from '@/api/helpers/destructureSearchResult.js'
import EmptyList from '@/components/multiUse/EmptyList.vue'
import LoadingSpinner from '@/components/multiUse/LoadingSpinner.vue'
import SearchCardBusiness from '@/components/search/SearchCardBusiness.vue'
import { BUSINESSES_LIST } from '@/constants/search/listTypes.js'
import { BUSINESS } from '@/constants/search/resultTypes.js'
import { GetUserFavorites } from '@/gql/queries/user.gql'

export default {
	components: {
		SearchCardBusiness,
		EmptyList,
		LoadingSpinner
	},
	data: function () {
		return {
			loading: 0,
			BUSINESSES_LIST,
			BUSINESS
		}
	},
	apollo: {
		favorites: {
			query: GetUserFavorites,
			update(data) {
				if (data?.user?.favoriteListings) {
					return {
						listings: [ ...data.user.favoriteListings ]
					}
				}
				return []
			},
			variables () {
				return {
					id: this.user.id
				}
			}
		}
	},
	computed: {
		...mapGetters('user', [ 'user' ]),
		showEmptyState() {
			return !this.hasFavorites && !this.loading
		},
		hasFavorites() {
			return !!this.favorites?.listings?.length
		},
		listings() {
			return this.favorites?.listings
		},
		destructuredItems() {
			const tempArray = []

			if (this.hasFavorites) {
				for (const index in this.listings) {
					const tempItem = this.listings[index]
					if (tempItem) {
						tempArray.push(destructureSearchResult(tempItem, BUSINESSES_LIST, BUSINESS))
					}
				}
				return tempArray
			} else {
				return []
			}
		}
	}
}
</script>
