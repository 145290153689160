<template>
	<component
		:is="activeIcon"
		class="w-8 h-8"
	/>
</template>

<script async>
import { ACCEPTED, BUSINESS_MESSAGE, CANCELLED, COMPLETED, READY } from '@/constants/preorders/orderHistoryActions.js'

export default {
	props: {
		action: {
			type: String,
			required: true
		}
	},
	computed: {
		activeIcon() {
			switch (this.action) {
				case BUSINESS_MESSAGE:
					return () => import('@/components/icons/MessageIcon.vue')
				case ACCEPTED:
					return () => import('@/components/icons/DispensaryIcon.vue')
				case CANCELLED:
					return () => import('@/components/icons/CancelledIcon.vue')
				case READY:
					return () => import('@/components/icons/DispensaryIcon.vue')
				case COMPLETED:
					return () => import('@/components/icons/CompletedCheckmark.vue')
				default:
					return ''
			}
		}
	}
}
</script>
