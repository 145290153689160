import apiService from '@/api/index.js'

const routeToFunctionMap = {
	userIndex: 'getReviews',
	userReviews: 'getReviews',
	userAbout: '',
	userFollowing: '',
	userPhotos: 'getPhotos',
	userStrains: 'getStrains'
}

export default {
	type: 'User',
	async getPage(routeTo) {
		const postData = {
			type: this.type,
			func_name: routeToFunctionMap[routeTo.name],
			data: { user: routeTo.params.user },
			firstLoad: routeTo.firstLoad || false
		}
		return await apiService.get(postData)
	}
}
