<template>
	<div class="w-full border-r border-gray-300 md:pr-4">
		<div class="flex items-center w-full px-3 py-2 border-b border-gray-300 md:pt-0">
			<UserAvatar
				:user="user"
				:height="60"
				:width="60"
			/>
			<div class="flex flex-wrap w-full pl-3 text-left">
				<h1 class="text-xl font-bold">
					{{ userName }}
				</h1>
				<div
					v-if="zip != 0"
					class="w-full mt-1 text-xs"
				>
					{{ location }}
				</div>
			</div>
		</div>
		<div class="px-0 border-b border-gray-300 md:w-full">
			<div class="flex flex-row py-3 text-center text-gray-500">
				<div class="w-1/2">
					<router-link
						:to="{name: 'userReviews'}"
						class=""
					>
						<span class="inline-block w-10 h-10 font-bold leading-9 border border-gray-300 border-solid rounded-full">
							{{ reviewCount }}
						</span>
						<div class="text-sm uppercase">
							Reviews
						</div>
					</router-link>
				</div>

				<div class="w-1/2">
					<router-link :to="{ name: 'userPhotos' }">
						<span class="inline-block w-10 h-10 font-bold leading-9 border border-gray-300 border-solid rounded-full">
							{{ photoCount }}
						</span>
						<div class="text-sm uppercase">
							Photos
						</div>
					</router-link>
				</div>
			</div>
		</div>
		<ProfileSideNav
			:nav-links="userNav"
			:auth="auth"
		/>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import ProfileSideNav from '@/components/multiUse/ProfileSideNav.vue'
import UserAvatar from '@/components/user/UserAvatar.vue'

export default {
	components: {
		ProfileSideNav,
		UserAvatar
	},
	data: function () {
		return {
			baseUrl: ''
		}
	},
	computed: {
		...mapGetters('user', [
			'userName',
			'userId',
			'user',
			'reviewCount',
			'photoCount',
			'location',
			'zip'
		]),
		...mapGetters('auth', [
			'auth'
		]),
		isProfileOwner() { return this.userId === this.auth.id },
		userNav() {
			const tempNav = [
				{ name: 'userReviews', url: 'reviews', text: 'Review' },
				{ name: 'userPhotos', url: 'photos', text: 'Photos' },
				{ name: 'userStrains', url: 'strains', text: 'Strains' }
			]
			if (this.isProfileOwner) {
				tempNav.unshift(
					{ name: 'userEdit', url: 'edit', text: 'Edit' },
					{ name: 'userPreOrders', url: 'pre-orders', text: 'Preorders' },
					{ name: 'userFavorites', url: 'favorites', text: 'Favorites' }
				)
			}
			return tempNav
		}
	},
	mounted() {
		const urlParts = this.$route.fullPath.split('/')
		if (this.$route.name != 'index') {
			urlParts.pop()
		}
		this.baseUrl = urlParts.join('/')
	}
}
</script>
