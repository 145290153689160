<template>
	<div class="text-left shadow-lg md:w-full md:shadow-none">
		<ul class="grid grid-flow-col m-0 overflow-x-auto list-none justify-items-center auto-cols-auto md:p-0 md:block md:w-full whitespace-nowrap md:whitespace-normal md:overflow-x-hidden">
			<li
				v-for="(link, index) in navLinks"
				:key="index"
				class="py-2 md:text-left md:w-full"
				:class="[pageIsActive(link.name) ?'border-green-500 border-b-2': 'md:border-gray-300 md:border-b']"
			>
				<RouterLink
					:to="{name: link.name}"
					class="w-full text-lg font-semibold md:border-t-0"
				>
					<div class="flex items-center md:ml-2">
						<div
							:class="[
								pageIsActive(link.name) ? 'bg-green-500' : 'bg-transparent',
								'md:flex hidden w-2.5 h-2.5 mr-3 rounded-full'
							]"
						/>
						<span
							:class="[
								'md:ml-2',
								pageIsActive(link.name) ? 'md:text-green-500 text-black' : 'text-blue-green md:text-black'
							]"
						>
							{{ link.text }}
						</span>
						<img
							class="hidden ml-auto mr-3 md:flex"
							src="@/assets/icons/right-arrow.svg"
							alt="Right Arrow"
							height="16"
							width="16"
						>
					</div>
				</RouterLink>
			</li>
		</ul>
	</div>
</template>

<script async>
export default {
	props: {
		navLinks: {
			type: Array,
			default: () => []
		},
		auth: {
			type: Object,
			default: () => ({})
		}
	},
	methods: {
		pageIsActive(name) {
			return this.$route.name === name
		}
	}
}
</script>

<style lang="scss" scoped>
.text-blue-green {
	color: #216869;
}
</style>
