<template>
	<div
		class="bg-transparent"
	>
		<h2 class="pt-2 pb-4 text-2xl font-bold">
			Reviews
		</h2>
		<template v-if="hasReviews">
			<TransitionGroup
				appear
				name="list-slide-up"
			>
				<ReviewCard
					v-for="(review, index) in reviews"
					:key="index"
					:listing-id="Number(review.listingId)"
					:review="review"
					:user="user"
					profile="user"
					:style="{'--index': index, '--perPage': perPage, '--page': page}"
					can-edit
					class="bg-white"
					@open-edit-modal="openEditModal"
					@open-report-modal="openReportModal"
					@open-delete-modal="openDeleteModal"
				/>
			</TransitionGroup>
			<ReportReviewModal
				:id="REPORT_REVIEW_MODAL"
				:review-to-report="reportedReview"
			/>
		</template>

		<template v-else>
			<EmptyList
				image-src="/img/no-reviews.jpg"
				copy="The user has no reviews yet."
			/>
		</template>

		<template v-if="canEdit">
			<EditReviewModal
				:id="EDIT_REVIEW_MODAL"
				profile="user"
				:review-edit="editData"
				:review-ratings="reviewRatings"
				:business="listingToEdit"
				:rating-types="ratingTypes"
				@update-review="handleUpdateReview"
			/>
			<DeleteReviewModal
				:id="DELETE_REVIEW_MODAL"
				:listing-name="reviewToDelete?.listingName"
				:review-id="reviewToDelete?.id"
				:listing-id="reviewToDelete?.listingId"
				@delete-review="handleDeleteReview"
			/>
		</template>
	</div>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import ReviewCard from '@/components/multiUse/ReviewCard.vue'
import { DELETE_REVIEW_MODAL, EDIT_REVIEW_MODAL, REPORT_REVIEW_MODAL } from '@/constants/modal/names.js'
import { ERROR, SUCCESS } from '@/constants/toast/type.js'
import { DeleteListingReview, UpdateListingReview } from '@/gql/business/mutations.gql'
import { GetUserReviews } from '@/gql/queries/user.gql'
import { gqlError, logError } from '@/utils/error-handling.js'
import { mapRatingsObjectToArray, mapRatingTypes, setReviewInput } from '@/utils/reviewMappers.js'

const USER_REVIEWS_QUERY_LIMIT = 8

export default {
	components: {
		EmptyList: () => import('@/components/multiUse/EmptyList.vue'),
		EditReviewModal: () => import('@/components/UI/modals/EditReviewModal.vue'),
		ReportReviewModal: () => import('@/components/UI/modals/ReportReviewModal.vue'),
		DeleteReviewModal: () => import('@/components/UI/modals/DeleteReviewModal.vue'),
		ReviewCard
	},
	data() {
		return {
			EDIT_REVIEW_MODAL,
			REPORT_REVIEW_MODAL,
			DELETE_REVIEW_MODAL,
			stopScroll: false,
			isMounted: false,
			page: 1,
			perPage: 15,
			reportedReview: {
				review: '',
				review_id: 0,
				listing_id: 0
			},
			reviewToDelete: {
				id: 0,
				listingName: '',
				listingId: 0
			},
			editData: {},
			reviewRatings: {},
			reviewsLimit: 15,
			ratingTypes: [],
			observed: false,
			USER_REVIEWS_QUERY_LIMIT
		}
	},
	apollo: {
		userReviewData: {
			query: GetUserReviews,
			update(data) {
				if (data.user) {
					const userReviews = data?.user?.reviews || []
					return {
						id: data.user.id,
						reviewsCount: data.user.reviewsCount,
						username: data.user.username,
						reviews: [ ...userReviews ]
					}
				}
				return {}
			},
			variables() {
				return {
					id: this.routeUserId,
					userName: this.routeUsername,
					limit: USER_REVIEWS_QUERY_LIMIT
				}
			}
		}
	},
	computed: {
		routeUserId() {
			return /^\d+$/.test(this.$route?.params?.user) ? parseInt(this.$route.params.user) : null
		},
		routeUsername() {
			if (!this.$route?.params?.user) return ''
			return !/^\d+$/.test(this.$route.params.user) ? this.$route.params.user : ''
		},
		userId() {
			return this.userReviewData?.id || ''
		},
		canEdit() {
			return this.auth.loggedIn && this.userId == this.auth.id
		},
		reportedReviewData() {
			return this.reviews.items[this.reportedReview.review_id]
		},
		hasReviews() {
			return !!(this.userReviewData?.reviews?.length)
		},
		reviews() {
			return this.userReviewData?.reviews || []
		},
		observerIndex() {
			return this.reviews?.length - (USER_REVIEWS_QUERY_LIMIT / 2) || 0
		},
		reviewsOffset() {
			return this.reviews?.length
		},
		reportData() {
			return {
				message: this.reportMessage,
				user_id: this.auth.id,
				review_id: parseInt(this.reportedReviewId),
				listing_id: this.business.listing_id,
				reason: this.selected
			}
		},
		bottomReviewId() {
			return this.loadedReviews.length - 1
		},
		listingToEdit() {
			return {
				listing_id: this.editData?.listing?.id
			}
		},
		...mapGetters('user', [ 'user' ]),
		...mapGetters('business', [ 'business' ]),
		...mapGetters('auth', [ 'auth' ])
	},
	watch: {
		userReviewData() {
			if (this.user?.reviewCount > this.reviewsOffset) {
				this.observeReviews()
			}
		},
		observed() {
			if (this.observed) {
				this.page++
				this.fetchMoreReviews()
				this.observed = false
			}
		}
	},
	mounted() {
		this.isMounted = true
	},
	beforeDestroy() {
		if (this.observer) {
			this.observer.disconnect()
		}
	},
	methods: {
		...mapMutations('modal', [ 'showModal' ]),
		...mapMutations('toast', [ 'showToast' ]),
		openEditModal(data) {
			this.editData = data
			const editId = parseInt(this.editData.id)
			const reviewToEdit = this.reviews.find(review => parseInt(review.id) === editId)
			this.ratingTypes = [ ...mapRatingTypes(reviewToEdit?.listing?.businessType) ]
			this.ratingTypes.forEach(type => {
				if (type.key && reviewToEdit?.ratings?.find(rating => rating.key === type.key)?.rating) {
					const score = parseInt(reviewToEdit.ratings.find(rating => rating.key === type.key).rating)
					this.reviewRatings = Object.assign({}, this.reviewRatings, { [type.key]: score })
				}
			})
			this.showModal(EDIT_REVIEW_MODAL)
		},
		openReportModal(data) {
			this.reportedReview.review = data.review
			this.reportedReview.review_id = parseInt(data.reviewid)
			this.reportedReview.listing_id = parseInt(data.listing_id)
			this.showModal(REPORT_REVIEW_MODAL)
		},
		openDeleteModal(data) {
			this.reviewToDelete.id = parseInt(data.id)
			this.reviewToDelete.listingName = data.listing?.name
			this.reviewToDelete.listingId = parseInt(data.listing?.id)
			this.showModal(DELETE_REVIEW_MODAL)
		},
		observeReviews() {
			this.$nextTick(() => {
				if (window && 'IntersectionObserver' in window) {
					const scrollTarget = document.getElementById(this.userReviewData.reviews[this.observerIndex]?.id)
					if (scrollTarget) {
						this.observer = new IntersectionObserver(entries => {
							entries.forEach(entry => {
								if (entry.isIntersecting) {
									this.observed = true
									this.observer.disconnect()
								}
							})
						})
						this.observer.observe(scrollTarget)
					}
				} else {
					this.observed = true
				}
			})
		},
		async fetchMoreReviews() {
			try {
				await this.$apollo.queries.userReviewData.fetchMore({
					variables: {
						offset: this.reviewsOffset,
						limit: USER_REVIEWS_QUERY_LIMIT
					},
					updateQuery(previousResult, { fetchMoreResult }) {
						if (!fetchMoreResult?.user?.reviews?.length) {
							return previousResult
						}
						const newReviews = fetchMoreResult.user.reviews
						return {
							__typename: previousResult.user.__typename,
							user:
								{
									__typename: previousResult.user.__typename,
									id: previousResult.user.id,
									username: previousResult.user.username,
									reviewsCount: previousResult.user.reviewsCount,
									displayName: previousResult.user.displayName,
									imageCount: previousResult.user.imageCount,
									reviews: [ ...previousResult.user.reviews, ...newReviews ]
								}
						}
					}
				})
			} catch (error) {
				logError(error)
			}
		},
		handleUpdateReview(id, review, ratings) {
			this.$apollo.mutate({
				mutation: UpdateListingReview,
				variables: {
					id: parseInt(id),
					reviewInput: setReviewInput(review, ratings),
					limit: USER_REVIEWS_QUERY_LIMIT
				},
				update: (store, { data: { listingReviewUpdate } }) => {
					const { user, __typename } = store.readQuery({
						query: GetUserReviews,
						variables: {
							id: this.routeUserId,
							userName: this.routeUsername,
							limit: USER_REVIEWS_QUERY_LIMIT
						}
					})
					const reviewsCopy = user?.reviews
					const ratingsTypename = reviewsCopy[0]?.ratings[0]?.__typename || ''
					const ratingsArray = mapRatingsObjectToArray(ratings, ratingsTypename)
					const { overallRating } = listingReviewUpdate
					const reviewToUpdate = reviewsCopy.find(review => review.id == id)
					const reviewIndex = reviewsCopy.map(review => review.id).indexOf(id.toString())
					const updatedReview = { ...reviewToUpdate, review, ratings: ratingsArray, overallRating }
					reviewsCopy.splice(reviewIndex, 1, updatedReview)
					const updatedUser = { ...user, reviews: reviewsCopy }
					store.writeQuery({ query: GetUserReviews, data: { user: updatedUser, __typename } })
				},
				optimisticResponse: {
					__typename: 'Mutation',
					listingReviewUpdate: {
						__typename: 'ListingReviewEdit',
						id: null,
						overallRating: 0,
						review
					}
				}
			}).then(() => {
				this.showToast({
					primaryText: 'Review Updated',
					type: SUCCESS
				})
			}).catch((error) => {
				this.showToast({
					primaryText: 'Invalid Review',
					secondaryText: error,
					type: ERROR
				})
				gqlError(error)
			})
		},
		handleDeleteReview(reviewId, listingId) {
			this.$apollo.mutate({
				mutation: DeleteListingReview,
				variables: {
					reviewId,
					listingId
				},
				update: (store, { data: { listingReviewDelete } }) => {
					const { user } = store.readQuery({
						query: GetUserReviews
					})
					const reviewIndex = user.reviews.map(review => review.id).indexOf(reviewId.toString())
					user.reviews.splice(reviewIndex, 1)
				}
			}).then(() => {
				this.showToast({
					primaryText: 'Review Deleted',
					type: SUCCESS
				})
			}).catch((error) => {
				this.showToast({
					primaryText: 'Deletion Failed',
					secondaryText: 'error',
					type: ERROR
				})
				gqlError(error)
			})
		}
	}
}
</script>
