<template>
	<form
		ref="userEdit"
		method="POST"
		class="w-full bg-white"
		@submit.prevent="submitForm"
	>
		<h2 class="pt-2 pb-4 text-2xl font-bold">
			Edit Profile
		</h2>

		<div class="grid grid-cols-1 pb-2 text-left divide-y-2 divide-gray-200 gap-y-6 md:divide-y-0">
			<div class="w-full md:mb-0">
				<WwImageUpload
					:auto-crop="true"
					:auto-crop-area=".99"
					:aspect-ratio="1/1"
					:initial-aspect-ratio="1/1"
					@image-updated="handleUpdateImage"
					@image-removed="handleRemoveImage"
				/>
				<div class="flex items-center h-10">
					<Transition name="fade">
						<p
							v-if="readyToSaveImage"
							class="italic"
						>
							Save to confirm changes to profile picture.
						</p>
					</Transition>
				</div>

				<button
					class="w-full px-3 py-2 font-bold text-white uppercase rounded-md h-11"
					:disabled="!readyToSaveImage"
					:class="!readyToSaveImage ? 'bg-gray-300 cursor-not-allowed' : 'bg-green-500'"
					@click.stop="handleSaveImage"
				>
					Save Profile Picture
				</button>
			</div>
		</div>

		<div class="w-full my-4 border" />
		<h3 class="mb-2 text-lg font-semibold">
			Profile Information
		</h3>
		<div v-html="message" />
		<div class="grid grid-cols-1 text-left md:grid-cols-2 gap-x-6">
			<div
				v-for="field in form.personalInformationFields"
				:key="field.id"
				class="w-full py-2"
			>
				<WwTextInput
					:id="field.id"
					v-model="editForm[field.id]"
					:value="editForm[field.id]"
					:input-type="field.type"
					:label="field.label"
					input-class="w-full"
					:required="field.required"
					:autocomplete="field.autocomplete"
					:state="inputFieldState(editForm[field.id], field.type)"
				/>
			</div>
		</div>
		<div class="w-full my-4 border" />
		<h3 class="mb-2 text-lg font-semibold">
			Favorites
		</h3>
		<div class="grid grid-cols-1 text-left md:grid-cols-2 gap-x-6">
			<div
				v-for="field in form.favoriteFields"
				:key="field.id"
				class="w-full py-2"
			>
				<WwTextInput
					:id="field.id"
					v-model="editForm[field.id]"
					:value="editForm[field.id]"
					:input-type="field.type"
					:label="field.label"
					input-class="w-full"
					:required="field.required"
					:autocomplete="field.autocomplete"
					:state="inputFieldState(editForm[field.id], field.type)"
				/>
			</div>
		</div>
		<div class="w-full my-4 border" />
		<div class="text-left">
			<div
				v-for="field in form.textAreaFields"
				:key="field.id"
				class="w-full py-2"
			>
				<WwTextInput
					:id="field.id"
					v-model="editForm[field.id]"
					:value="editForm[field.id]"
					:input-type="TEXTAREA"
					:label="field.label"
					input-class="w-full h-24"
					:required="field.required"
					:autocomplete="field.autocomplete"
					:state="inputFieldState(editForm[field.id], field.type)"
				/>
			</div>
		</div>
		<div class="flex w-full py-3">
			<button
				class="w-full px-3 py-2 font-bold text-white uppercase bg-green-500 rounded-md focus:ring focus:ring-green-300 focus:border-gray-300 md:ml-auto md:w-auto"
				type="submit"
				value="Submit"
			>
				Update Profile
			</button>
		</div>
	</form>
</template>

<script async>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import WwLazyLoader from '@/components/UI/WwLazyLoader.vue'
import WwTextInput from '@/components/UI/WwTextInput.vue'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import { EMAIL, NUMBER, TEXT, TEXTAREA } from '@/constants/text-inputs/types.js'
import { ERROR, SUCCESS } from '@/constants/toast/type.js'
import isAuthUser from '@/utils/isAuthUser.js'
import { inputFieldState } from '@/validators/text-inputs.js'

const WwImageUpload = () => ({
	component: import('@/components/UI/WwImageUpload.vue'),
	loading: WwLazyLoader
})

export default {
	components: {
		WwTextInput,
		WwImageUpload
	},
	data() {
		return {
			isMounted: false,
			image: '',
			customImage: '',
			imageCropCoords: {},
			message: '',
			readyToSaveImage: false,
			form: {
				personalInformationFields: [
					{ label: 'Zip (Default Location)', value: '', id: 'zip', type: NUMBER, autocomplete: 'postal-code' },
					{ label: 'Website', value: '', id: 'website', type: TEXT, autocomplete: 'url' }
				],
				favoriteFields: [
					{ label: 'Favorite Club', value: '', id: 'fav_club', type: TEXT, autocomplete: 'on' },
					{ label: 'Favorite Tool', value: '', id: 'tool', type: TEXT, autocomplete: 'on' },
					{ label: 'Favorite Strain', value: '', id: 'fav_strain', type: TEXT, autocomplete: 'on' },
					{ label: 'Favorite Movie', value: '', id: 'fav_movie', type: TEXT, autocomplete: 'on' },
					{ label: 'Favorite Concentrate', value: '', id: 'fav_con', type: TEXT, autocomplete: 'on' }
				],
				textAreaFields: [
					{ label: 'About Me', value: '', id: 'about', autocomplete: 'off' },
					{ label: 'General Interests', value: '', id: 'interests', autocomplete: 'off' },
					{ label: 'Hobbies', value: '', id: 'hobbies', autocomplete: 'off' },
					{ label: 'First CBD Experience', value: '', id: 'first_ex', autocomplete: 'off' },
					{ label: 'Best Time', value: '', id: 'best_time', autocomplete: 'off' }
				]
			},
			TEXT,
			EMAIL,
			NUMBER,
			TEXTAREA,
			INPUT_STATES,
			inputFieldState
		}
	},
	computed: {
		...mapGetters('user', [
			'userEdit',
			'profilePicture'
		]),
		...mapGetters('auth', [
			'auth'
		]),
		editForm() {
			return this.userEdit
		},
		initialImage() {
			return this.editForm.profile_picture
		},
		imageInfo() {
			return {
				user_id: this.userEdit.user_id,
				main: 1,
				image: this.image || false
			}
		}
	},
	watch: {
		profilePicture() {
			this.readyToSaveImage = false
			this.editForm.profile_picture = this.profilePicture
		},
		auth: {
			deep: true,
			handler(currentAuth) {
				if (!isAuthUser(currentAuth, this.$route.params.user)) {
					this.$router.push({ name: 'userIndex', params: { user: this.$route.params.user } })
				}
			}
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		...mapActions('user', [
			'updateUserProfile',
			'uploadPhoto'
		]),
		...mapMutations('toast', [ 'showToast' ]),
		handleUpdateImage(image) {
			this.image = image
			this.readyToSaveImage = true
		},
		customCroppedFunction({ data }) {
			this.imageCropCoords = data
		},
		handleRemoveImage() {
			// NOTE this might be a bit odd,
			// but this is so that if a request accidentally gets
			// sent with a blank image it doesn't delete
			// (api specifically looks for this string to confirm)
			this.image = 'DELETE IMAGE'
			this.readyToSaveImage = false
		},
		async handleSaveImage() {
			let message = 'Profile Photo Updated'
			if (this.image === 'DELETE IMAGE') message = 'Profile Photo Removed'
			try {
				const response = await this.uploadPhoto(this.imageInfo)
				if (response.success) {
					this.image = ''
					this.readyToSaveImage = false
					this.editForm.profile_picture = this.response.photo.filename
					this.showToast({
						primaryText: 'Success',
						secondaryText: message,
						type: SUCCESS
					})
				}
			} catch (error) {
				this.showToast({
					primaryText: 'Error',
					secondaryText: error,
					type: ERROR
				})
			}
		},
		async submitForm() {
			try {
				await this.updateUserProfile(this.editForm)
				this.showToast({
					primaryText: 'Success',
					secondaryText: 'User profile updated',
					type: SUCCESS
				})
			} catch (error) {
				this.showToast({
					primaryText: 'Error',
					secondaryText: error,
					type: ERROR
				})
			}
		}
	}
}
</script>
