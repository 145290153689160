<template>
	<div>
		<h2 class="pt-6 text-xl font-bold text-left">
			Order History
		</h2>
		<ul class="grid grid-cols-1 gap-4 text-left divide-y-2 divide-gray-200 divide-solid">
			<li
				v-for="(note, index) in notes"
				:key="index"
				class="flex items-start pt-4 space-x-3 md:items-center"
			>
				<OrderHistoryIcon :action="note.action" />
				<div class="grid grid-cols-1 md:flex md:space-x-3">
					<div class="flex items-center w-full space-x-3 md:w-auto">
						<span class="text-gray-500">
							{{ getDate(note.creation_time) }}
						</span>
						<span v-if="hasLabel(note.action)">
							{{ getLabel(note.action) }}
						</span>
					</div>
					<div class="flex items-center w-full space-x-3 md:w-auto">
						<strong
							v-if="hasNoteContent(note.content)"
							class="font-bold capitalize"
						>
							{{ note.action }}:
						</strong>
						<strong
							v-else
							class="font-bold capitalize"
						>
							{{ note.action }}
						</strong>
						<span v-if="note.content">
							{{ note.content }}
						</span>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script async>
import OrderHistoryIcon from '@/components/order/OrderHistoryIcon.vue'
import { ACCEPTED, BUSINESS_MESSAGE, CANCELLED, COMPLETED, READY } from '@/constants/preorders/orderHistoryActions.js'
import { formatUnixDate, formatUnixTime } from '@/utils/formatUnixTime.js'

export default {
	components: {
		OrderHistoryIcon
	},
	props: {
		notes: {
			type: Array,
			required: true
		}
	},
	methods: {
		hasLabel(action) {
			return action !== BUSINESS_MESSAGE
		},
		hasNoteContent(content) {
			return !!content?.length
		},
		getDate(unixTime) {
			return `${formatUnixDate(unixTime)} ${formatUnixTime(unixTime)}`
		},
		getLabel(action) {
			const statuses = [ ACCEPTED, CANCELLED, READY, COMPLETED ]
			return statuses.includes(action) ? 'Status changed to ' : ''
		}
	}
}
</script>
