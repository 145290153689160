<template>
	<div class="bg-white rounded-sm">
		<h2 class="pt-2 pb-4 text-2xl font-bold">
			Online Preorders
		</h2>
		<WwTabs @selectTab="selectTab">
			<WwTab
				:is-active="activeTab === ACTIVE_ORDERS"
				name="Active Orders"
			>
				<EmptyList
					v-if="!orders || orders.length === 0"
					image-src="/img/no-orders.png"
					:copy="noOrdersCopy"
					class="my-4"
				/>
				<UserPreOrderCard
					v-for="(order, index) in orders"
					:key="index"
					:pre-order="orders[index]"
				/>
			</WwTab>
			<WwTab
				:is-active="activeTab === INACTIVE_ORDERS"
				name="Inactive Orders"
			>
				<EmptyList
					v-if="!inactiveOrders || inactiveOrders.length === 0"
					image-src="/img/no-orders.png"
					:copy="noOrdersCopy"
					class="my-4"
				/>
				<UserPreOrderCard
					v-for="(order, index) in inactiveOrders"
					:key="index"
					:pre-order="inactiveOrders[index]"
				/>
			</WwTab>
		</WwTabs>
	</div>
</template>

<script async>

import { mapGetters } from 'vuex'

import EmptyList from '@/components/multiUse/EmptyList.vue'
import WwTab from '@/components/UI/tabs/WwTab.vue'
import WwTabs from '@/components/UI/tabs/WwTabs.vue'
import { PRE_ORDER_TAB_TYPES } from '@/constants/preorders/index.js'
import isAuthUser from '@/utils/isAuthUser.js'

const { ACTIVE_ORDERS, INACTIVE_ORDERS } = PRE_ORDER_TAB_TYPES

export default {
	components: {
		EmptyList,
		UserPreOrderCard: () => import('./components/PreOrderCard.vue'),
		WwTab,
		WwTabs
	},
	data() {
		return {
			tabs: PRE_ORDER_TAB_TYPES,
			...PRE_ORDER_TAB_TYPES,
			activeTab: ACTIVE_ORDERS
		}
	},
	computed: {
		...mapGetters('user', [
			'preOrders',
			'user'
		]),
		...mapGetters('auth', [
			'auth'
		]),
		noOrdersCopy() {
			const adj = this.activeTab === ACTIVE_ORDERS ? 'active' : 'inactive'
			return `You have no ${adj} orders!`
		},
		orders() {
			if (this.activeTab === ACTIVE_ORDERS) {
				return this.activeOrders
			}
			return this.inactiveOrders
		},
		activeOrders () {
			const activeOrderArray = []
			for (const order in this.preOrders) {
				const activeOrderStatus = this.trimAction(this.preOrders[order].status)
				if (activeOrderStatus == 'pending' || activeOrderStatus == 'accepted' || activeOrderStatus == 'ready') {
					activeOrderArray.push(this.preOrders[order])
				}
			}
			return activeOrderArray
		},
		inactiveOrders () {
			const inactiveOrderArray = []
			for (const order in this.preOrders) {
				const inactiveOrderStatus = this.trimAction(this.preOrders[order].status)
				if (inactiveOrderStatus == 'business cancelled' || inactiveOrderStatus == 'user cancelled' || inactiveOrderStatus == 'completed' || inactiveOrderStatus == 'declined') {
					inactiveOrderArray.push(this.preOrders[order])
				}
			}
			return inactiveOrderArray
		}
	},
	watch: {
		auth: {
			deep: true,
			handler(currentAuth) {
				if (!isAuthUser(currentAuth, this.$route.params.user)) {
					this.$router.push({ name: 'userIndex', params: { user: this.$route.params.user } })
				}
			}
		}
	},
	methods: {
		myTrim(x) { // searches for any spaces from the beginning of the string and from the end of string. If found then it is replaced by empty string
			return x.replace(/^\s+|\s+$/gm, '')
		},
		trimAction(status) {
			const str = this.myTrim(status)
			return str
		},
		selectTab(name) {
			name === 'Active Orders' ? this.activeTab = ACTIVE_ORDERS : this.activeTab = INACTIVE_ORDERS
		}
	}
}
</script>
