<template>
	<div class="w-full pt-4 mx-auto lg:w-5/6 background-fill profile-content profile-deals">
		<div class="flex flex-wrap lg:p-2">
			<div class="w-full mb-4">
				<OrderDetail :order="preOrder" />
				<OrderHistory
					v-if="notes.length"
					:notes="notes"
				/>
				<!-- <OrderMessenger v-if="false" /> -->
			</div>
		</div>
	</div>
</template>

<script async>
// import OrderMessenger from '@/components/order/OrderMessenger.vue'
import { mapGetters } from 'vuex'

import OrderDetail from '@/components/order/OrderDetail.vue'
import OrderHistory from '@/components/order/OrderHistory.vue'

export default {
	components: {
		OrderDetail,
		OrderHistory
		// OrderMessenger
	},
	computed: {
		...mapGetters('user', [
			'preOrder'
		]),
		...mapGetters('auth', [
			'auth'
		]),
		notes() {
			return this.preOrder?.business?.notes || []
		}
	}
}
</script>
