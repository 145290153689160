var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left shadow-lg md:w-full md:shadow-none"},[_c('ul',{staticClass:"grid grid-flow-col m-0 overflow-x-auto list-none justify-items-center auto-cols-auto md:p-0 md:block md:w-full whitespace-nowrap md:whitespace-normal md:overflow-x-hidden"},_vm._l((_vm.navLinks),function(link,index){return _c('li',{key:index,staticClass:"py-2 md:text-left md:w-full",class:[_vm.pageIsActive(link.name) ?'border-green-500 border-b-2': 'md:border-gray-300 md:border-b']},[_c('RouterLink',{staticClass:"w-full text-lg font-semibold md:border-t-0",attrs:{"to":{name: link.name}}},[_c('div',{staticClass:"flex items-center md:ml-2"},[_c('div',{class:[
							_vm.pageIsActive(link.name) ? 'bg-green-500' : 'bg-transparent',
							'md:flex hidden w-2.5 h-2.5 mr-3 rounded-full'
						]}),_vm._v(" "),_c('span',{class:[
							'md:ml-2',
							_vm.pageIsActive(link.name) ? 'md:text-green-500 text-black' : 'text-blue-green md:text-black'
						]},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(link.text)+"\n\t\t\t\t\t")]),_vm._v(" "),_c('img',{staticClass:"hidden ml-auto mr-3 md:flex",attrs:{"src":require("@/assets/icons/right-arrow.svg"),"alt":"Right Arrow","height":"16","width":"16"}})])])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }