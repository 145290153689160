<template>
	<DefaultLayout>
		<div class="flex flex-wrap w-full mx-auto lg:max-w-6xl lg:min-w-6xl">
			<UserNavBar class="flex flex-col w-full md:w-1/3 md:p-2" />
			<div class="w-full p-3 md:w-2/3">
				<router-view />
			</div>
		</div>
	</DefaultLayout>
</template>

<script async>
import { mapActions } from 'vuex'

import userModule from '@/store/modules/userModule.js'

import UserNavBar from './components/NavBar.vue'

export default {
	components: {
		DefaultLayout: () => import('@/layouts/default/Index.vue'),
		UserNavBar
	},
	beforeRouteUpdate: function (to, from, next) {
		this.getPage(to)
		next()
	},
	mounted: function() {
		this.getPage(this.$route)
	},
	created() {
		this.$store.registerModule('user', userModule)
	},
	beforeDestroy() {
		this.$store.unregisterModule('user')
	},
	methods: {
		...mapActions('user', [
			'getPage'
		])
	}
}
</script>
